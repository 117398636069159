/* global console, document, Excel, Office */
import "../style.css";
import { jwtDecode } from "jwt-decode";
import { authorizeUser, updateModel } from "../helpers/middle-tier-calls";

Office.onReady(async (info) => {
  // Check that we loaded into Excel
  if (info.host === Office.HostType.Excel) {
    await tryCatch(setSheetsDropdown);
    // document.getElementById("requestButton").onclick = async () => await tryCatch(testInvoke);
    document.getElementById("requestButton").onclick = async () => await tryCatch(submitUrl);
    document.getElementById("target_year").onchange = changeYear;
    document.getElementById("target_quarter").onchange = changeQuarter;
    // setHandler();
  }
});

async function tryCatch(callback) {
  try {
    await callback();
  } catch (error) {
    // TODO: ensure errors are logged at Sentry.io or similar
    console.error("Error caught: ", error);
    document.getElementById("requestButton").innerHTML = "Update Model";
    let element = resetMessageUI("apiResponse");
    displayError(element, "Something went wrong, please retry.");
  }
}

async function setSheetsDropdown() {
  await Excel.run(async (context) => {
    const sheetsSelect = document.getElementById("model_sheet");
    const sheets = context.workbook.worksheets;
    sheets.load("items/name,items/visibility");
    await context.sync();

    sheets.items.forEach((item) => {
      if (item.visibility === "Visible") sheetsSelect.add(new Option(item.name));
    });
  }).catch(error => {
    // Re-throw the error to be caught upstream
    throw error;
  });
}

async function changeYear(event) {
  document.getElementById("comparison_year").value = event.target.value - 1;
}

async function changeQuarter(event) {
  document.getElementById("comparison_quarter").value = event.target.value;
}

function validateForm() {

  let form = document.getElementById("main-form");
  if ( ! form.checkValidity() ) {
    // Form is invalid. The :invalid pseudo class will be added to all invalid form fields
    form.querySelectorAll(":invalid").forEach(element => {
      // Add class that highlights the invalid field for the user
      toggleInvalidFormFieldClass(element);
      // Add a listener for toggling the class when the user updates the field
      addEventListenerToFormField(element);
    });
    // Display useful message to user about form invalidation
    let display_error_element = resetMessageUI("apiResponse")
    displayError(display_error_element, 'Please add values to the highlighted fields')
    return false;
  }

  // Form is valid
  return true;
}

function toggleInvalidFormFieldClass(element) {
  let element_to_toggle = element;
  let css_class = 'form-validation-fail';
  // For the ticker, we target the parent <label> tag due to how this field is marked up
  if (element.id == 'ticker') {
    element_to_toggle = element.closest('label');
  }
  if (element.value.trim() === "") {
    element_to_toggle.classList.add(css_class);
  } else {
    element_to_toggle.classList.remove(css_class);
  }
}

function addEventListenerToFormField(element) {
  // We only add the event listener if it has not previously been added
  if (element.nodeName == 'INPUT' && element.oninput == null) {
    element.addEventListener('input', (event) => toggleInvalidFormFieldClass(event.target));
  }
  if (element.nodeName == 'SELECT' && element.onchange == null) {
    element.addEventListener('change', (event) => toggleInvalidFormFieldClass(event.target));
  }
}

async function submitUrl() {

  // This is the earliest place to catch the form being submitted without re-factoring
  if ( ! validateForm() ) {
    return false;
  }

  await Excel.run(async (context) => {
    console.log("Enter submiturl");

    // Clear response message
    const responseMessage = resetMessageUI("response");
    const apiResponse = resetMessageUI("apiResponse");
    const requestButton = document.getElementById("requestButton");
    requestButton.innerHTML = '<span class="loading loading-spinner"></span>';

    const model_sheet = document.getElementById("model_sheet").value;
    const sourceSheet = context.workbook.worksheets.getItem(model_sheet);
    // const payload = {};
    const payload = await buildPayload(context, sourceSheet);

    // Check URL is present and valid
    const url = document.getElementById("sec_url").value;
    const useFakeApi = document.getElementById("useFakeApi").checked;
    if (url) {
      if (isValidHttpUrl(url)) {
        console.log("url is valid");
        // displaySuccess(responseMessage, `You requested URL:<br/> ${url}`);
        // if (useFakeApi) responseMessage.innerHTML += "<br/>using cached response.";

        // Send the request
        const startTime = performance.now();
        const result = await requestUpdateFromApi(useFakeApi, context, payload);

        if (result.error) {
          displayError(apiResponse, result.error);
        } else if (result.data.error_message) { 
          displayError(apiResponse, result.data.error_message);
        } else {
          console.log("Response data:", result.data);
          const timestamp = generateTimestamp();
          await insertSummarySheet(context, result.data, timestamp);
          if (responseContainsActualPeriodData(result.data)) {
            await insertUpdateLog(context, result.data, timestamp, sourceSheet);
            displaySuccess(apiResponse, "Update Completed");
          } else {
            displayError(apiResponse, "Response received without actuals data");
          }
        }

        // Calculate response time
        const endTime = performance.now();
        apiResponse.innerHTML += `<br/>Time taken: ${Math.round((endTime - startTime) / 1000)}s`;
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        displayError(responseMessage, `Invalid URL:<br/> ${url}`);
      }
    } else {
      displayError(responseMessage, "Please supply URL field");
    }
    requestButton.innerHTML = "Update Model";

    // sync the context to run the previous API call, and return.
    return context.sync();
  }).catch(error => {
    // Re-throw error to be caught upstream
    throw error;
  });
}

function responseContainsActualPeriodData(data) {
  return (
    data.actual_period &&
    Array.isArray(data.actual_period) &&
    data.actual_period.length > 0 &&
    Array.isArray(data.actual_period[0]) &&
    data.actual_period[0].length > 0
  );
}

async function buildPayload(context, worksheet) {



  const ticker = document.getElementById("ticker").value;
  const sec_url = document.getElementById("sec_url").value;
  const line_items_column = document.getElementById("line_items_column").value;
  const target_year = document.getElementById("target_year").value;
  const target_quarter = document.getElementById("target_quarter").value;
  const target_column = document.getElementById("target_column").value;
  const comparison_year = document.getElementById("comparison_year").value;
  const comparison_quarter = document.getElementById("comparison_quarter").value;
  const comparison_column = document.getElementById("comparison_column").value;

  const workbook = context.workbook;
  workbook.load("name");
  worksheet.load("name");
  await context.sync();

  const payload = {
    ticker,
    excel_file_name: workbook.name,
    sec_url,
    line_items: {
      excel_sheet: worksheet.name,
      column: line_items_column,
      rows: [],
    },
    target_period: {
      excel_sheet: worksheet.name,
      year: Number(target_year),
      column: target_column,
      rows: [],
    },
    comparison_period: {
      excel_sheet: worksheet.name,
      year: Number(comparison_year),
      column: comparison_column,
      rows: [],
    },
  };
  if (target_quarter !== "0") payload.target_period.quarter = Number(target_quarter);
  if (comparison_quarter !== "0") payload.comparison_period.quarter = Number(comparison_quarter);

  await getColumnValues(context, worksheet, payload.line_items);
  await getColumnValues(context, worksheet, payload.target_period);
  await getColumnValues(context, worksheet, payload.comparison_period);
  return payload;
}

async function getColumnValues(context, sheet, obj) {
  const range = sheet.getRange(`${obj.column}:${obj.column}`).getUsedRange();
  range.load({ select: "text,formulas,rowIndex" });
  await context.sync();

  // Excel gives us an array of arrays for the text and formulas of the range
  // Outer array is rows, inner array is columns
  range.text.forEach((row, index) => {
    const text = row[0];
    const formulaString = range.formulas[index][0].toString(); // could be a number type
    const formulaExEquals = formulaString.substring(1);

    // include rows that have have a non-empty text, discarding formulas
    if (text && formulaString[0] !== "=") {
      obj.rows.push({ row: index + range.rowIndex + 1, text });
    } else if (text && formulaString[0] === "=" && !isNaN(formulaString.substring(1))) { // "=234" counts as a number 
      obj.rows.push({ row: index + range.rowIndex + 1, text: formulaExEquals });
    }
  });
}

function isValidHttpUrl(value) {
  try {
    const newUrl = new URL(value);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
}

function resetMessageUI(elementId) {
  const element = document.getElementById(elementId);
  element.innerHTML = "";
  element.classList.remove("text-success", "text-error");
  return element;
}

function displaySuccess(element, message) {
  element.classList.add("text-success");
  element.innerHTML = message;
}

function displayError(element, message) {
  element.classList.add("text-error");
  element.innerHTML = message;
}

async function requestUpdateFromApi(useFakeApi, context, payload) {
  console.log("Entering requestUpdateFromApi");
  if (useFakeApi) {
    console.log("Loading sample response");
    const response = await fetch(`./amzn_response_2024q1.json`);
    // const response = await fetch(`./response_error_message.json`);
    if (!response.ok) return { error: "Failed to load sample response" };
    const data = await response.json();
    await delay(1000);
    return { data };
  } else {
    console.log("Using real API");
    try {
      let response = await updateModel(payload);
      console.log("Response from middle tier", response);
      return response;
    } catch (error) {
      console.log(error);
      return { error: `Request failed: ${error.message}` };
    }
  }
}

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function generateTimestamp() {
  return new Date()
    .toISOString()
    .replaceAll(/[:TZ\.\-]/g, "")
    .substring(2, 14);
}

async function insertSummarySheet(context, data, timestamp) {
  const sheet = context.workbook.worksheets.add(`Summary - ${timestamp}`);
  if (data.summary && Array.isArray(data.summary) && data.summary.length && Array.isArray(data.summary[0])) {
    
    const margin_column = sheet.getRange(`A:A`)
    margin_column.format.columnWidth = 20

    const title_cell =  sheet.getRange(`B2`)
    title_cell.values = `Update Summary`
    title_cell.format.font.size = 18;
    title_cell.format.font.bold = true;

    const ticker = document.getElementById("ticker").value
    const year = document.getElementById("target_year").value
    const quarter = document.getElementById("target_quarter").value
    quarter ??= `FY`
    const subtitle_cell =  sheet.getRange(`B3`)
    subtitle_cell.values = `${ticker} ${year}-${quarter}`
    
    const sec_url = document.getElementById("sec_url").value;
    const url_cell =  sheet.getRange(`B4`)
    url_cell.values = `=hyperlink("${sec_url}")`
    url_cell.format.font.underline = `single`;
    url_cell.format.font.color = `#0000FF`;

    
    for (const item of data.summary[0]) {
      if (item.row && item.text) {
        const cell = sheet.getRange(`B${item.row+5}`);
        cell.values = [[item.text]];
      }
    }
  } else {
    sheet.getRange("B1").values = [["No summary data provided"]];
  }
  //sheet.activate();
  await context.sync();
} 

async function insertUpdateLog(context, data, timestamp, sourceSheet) {

  // write line items, comparison, and estimate columns
  const sheetName = `Update Log - ${timestamp}`;
  const sheet = context.workbook.worksheets.add(sheetName.substring(0, 30)); // Max sheet name 31 chars
  sheet.freezePanes.freezeRows(1);

  const line_items_column = document.getElementById("line_items_column").value;
  const sourceLineItems = sourceSheet.getRange(`${line_items_column}:${line_items_column}`);
  sheet.getRange("A:A").copyFrom(sourceLineItems, Excel.RangeCopyType.formats);
  sheet.getRange("A:A").copyFrom(sourceLineItems, Excel.RangeCopyType.values);

  const comparison_column = document.getElementById("comparison_column").value;
  const sourceComparisonColumn = sourceSheet.getRange(`${comparison_column}:${comparison_column}`);
  sheet.getRange("B:B").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);
  sheet.getRange("B:B").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.values);

  const target_column = document.getElementById("target_column").value;
  const sourceTargetColumn = sourceSheet.getRange(`${target_column}:${target_column}`);
  sheet.getRange("C:C").copyFrom(sourceTargetColumn, Excel.RangeCopyType.formats);
  sheet.getRange("C:C").copyFrom(sourceTargetColumn, Excel.RangeCopyType.values);
  await context.sync(); 

  // update original model
  // copy comparison column formats & formulas to actual column
  sourceTargetColumn.copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);
  const formulaRanges = sourceComparisonColumn.getSpecialCellsOrNullObject(Excel.SpecialCellType.formulas);
  const offsetRange = sourceSheet.getRange(`${comparison_column}:${target_column}`);
  offsetRange.load('columnCount');
  await context.sync();
  const actualOffset = offsetRange.columnCount-1;
  if (formulaRanges.isNullObject) {
        console.log("No comparison cells have formulas");
      } else {
        formulaRanges.load('areaCount','areas');
        await context.sync();
        const formulaRangeAreas = formulaRanges.areas;
        formulaRangeAreas.load('items','rowIndex','rowCount');
        await context.sync();
        for (const formulaRange of formulaRangeAreas.items) {
          const actualFormulaRange = formulaRange.getOffsetRange(0,actualOffset);
          actualFormulaRange.copyFrom(formulaRange);
          actualFormulaRange.format.fill.color = "LightBlue"; //#ADD8E6
          
          // write "formula" to source doc column in update log
          const firstRow = formulaRange.rowIndex + 1
          const lastRow = formulaRange.rowIndex + formulaRange.rowCount
          sheet.getRange(`F${firstRow}:F${lastRow}`).values = 'Formula';
        }
      }

  // delete estimated numbers
  const targetNumberRanges = sourceTargetColumn.getSpecialCellsOrNullObject(
    Excel.SpecialCellType.constants, Excel.SpecialCellValueType.numbers);
  await context.sync();
  if (targetNumberRanges.isNullObject) {
        console.log("No values to clear");
      } else {
        targetNumberRanges.clear(Excel.ClearApplyTo.contents);
      }

  // overwrite actuals in actual column
  for (const item of data.actual_period[0]) {
    if (item.row && item.text) {
      const cell = sourceSheet.getRange(`${target_column}${item.row}`);
      cell.values = [[item.text]];
      cell.format.fill.color = "SpringGreen";
    }
  }
  // highlight errors
  for (const item of data.actual_period[2]) {
    if (item.row && item.text === "Error") {
      const cell = sourceSheet.getRange(`${target_column}${item.row}`);
      cell.format.fill.color = "Tomato";
      cell.values = [['No Value']];
      cell.format.font.color = "White";
    }
  }

  // continue writing update log
  sheet.getRange("D:D").copyFrom(sourceTargetColumn, Excel.RangeCopyType.formats);
  sheet.getRange("D:D").copyFrom(sourceTargetColumn, Excel.RangeCopyType.values);
  sheet.getRange("E:E").copyFrom(sourceComparisonColumn, Excel.RangeCopyType.formats);

  // location and source doc
  for (const item of data.actual_period[1]) {
    if (item.row && item.text) {
      const source_doc_cell = sheet.getRange(`F${item.row}`);
      const sec_url = document.getElementById("sec_url").value;
      source_doc_cell.values = `=hyperlink("${sec_url}","Earnings Release")`;
      source_doc_cell.format.font.underline = `single`;
      source_doc_cell.format.font.color = `#0000FF`;
      const location_cell = sheet.getRange(`G${item.row}`);
      location_cell.values = [[item.text]];
    }
  }

  // Anomaly
  for (const item of data.actual_period[3]) {
    if (item.row && item.text) {
      const cell = sheet.getRange(`I${item.row}`);
      cell.values = [[item.text]];
    }
  }

  // Anomaly level
  for (const item of data.actual_period[2]) {
    if (item.row && item.text) {
      const level_cell = sheet.getRange(`H${item.row}`);
      level_cell.values = [[item.text]];
      const row = level_cell.getEntireRow();
      
      if (item.text === "Error") {
          row.format.fill.color = "Tomato";
          row.format.font.color = "White";
        } else if (item.text === "Warning") {
          row.format.font.color = "DarkOrange";
        }
      }
    }

  const detail_range = sheet.getRange(`F:I`);
  detail_range.format.columnWidth = 100;

  // update log headers
  sheet.getRange("B1").values = [["Comparison"]];
  sheet.getRange("C1").values = [["Estimate"]];
  sheet.getRange("D1").values = [["Actual"]];
  sheet.getRange("E1").values = [["Surprise"]];
  sheet.getRange("F1").values = [["Source Doc"]];
  sheet.getRange("G1").values = [["Location"]];
  sheet.getRange("H1").values = [["Anomaly Level"]];
  sheet.getRange("I1").values = [["Anomaly"]];

  // write surprise
  await context.sync();
  const actualColumn = sheet.getRange("D:D").getUsedRange();
  actualColumn.load("values,text");
  await context.sync();

  actualColumn.values.forEach((row, index) => {
    const value = row[0];
    if (typeof value === "number") {
      const text = actualColumn.text[index][0];
      const surpriseCell = sheet.getRange(`E${index + 1}`);
      if (!text.includes("%")) {
        surpriseCell.formulas = [[`=(D${index + 1}-C${index + 1})/C${index + 1}`]];
        surpriseCell.numberFormat = [["0.0%"]];
      } else if (text.includes("%")) {
        surpriseCell.formulas = [[`=TEXT(ROUND((D${index + 1}-C${index + 1})*10000,0),0)&" bps"`]];
        surpriseCell.format.horizontalAlignment = "Right";
      }
    }
  });

  await context.sync();
}
