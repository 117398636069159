import * as $ from "jquery";

export async function updateModel(payload) {
  console.log("Entering updateModel, payload:", payload);
  try {
    let bearer_token = await Clerk.session.getToken();
    const apiResponse = await fetch("https://api.tenkay.ai/v1/model-update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearer_token
      },
      body: JSON.stringify(payload),
    });
    if (apiResponse.status != 200 || apiResponse.error) {
      console.log(apiResponse);
      return { error: "Request failed." };
    }
    const data = await apiResponse.json();
    console.log(data);
    return { data };
  } catch (error) {
    console.log(error);
    return { error: `Request failed: ${error.message}` };
  }
}

export async function testPing() {
  console.log("Entering testPing");
  try {
    const response = await $.ajax({
      type: "GET",
      url: `/ping`,
      // headers: { Authorization: "Bearer " + middletierToken },
      cache: false,
    });
    console.log("Response received.");
    return response;
  } catch (err) {
    console.log(`Error from middle tier. \n${err.responseText || err.message}`);
    throw err;
  }
}
